// Importer les fonctions nécessaires depuis Firebase SDK
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAISKycKnX4vX9eNWL1tCwlGUhXriGesVc",
  authDomain: "pngp-235.firebaseapp.com",
  projectId: "pngp-235",
  storageBucket: "pngp-235.appspot.com",
  messagingSenderId: "396754451874",
  appId: "1:396754451874:web:68255b930b12b71bd546fb",
  measurementId: "G-CRKVVC9D9Y"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialiser Firestore
const db = getFirestore(app);  // Utilisation de getFirestore pour la version modulaire

// Exporter la base de données Firestore
export { db };
