import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <>
      <section className="hero">
        <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center text-center text-md-start">
          <div className="hero-text">
            <h1>Parti de la Nouvelle Génération Politique (PNGP)</h1>
            <p>Pour un avenir inclusif, durable et innovant.</p>
            <div className="hero-buttons">
  <Link className="btn btn-danger" to="/adhesion">
    <i className="fas fa-user-plus"></i> Rejoindre le PNGP
  </Link>
  <Link className="btn btn-primary" to="/programme">
    <i className="fas fa-info-circle"></i> Consulter le Programme
  </Link>
</div>

          </div>
          <div className="hero-image mt-4 mt-md-0">
            <img src="oumar.jpg" alt="Emblème PNGP" />
          </div>
        </div>
      </section>

      {/* Section About */}
      <section className="about py-5">
        <div className="container">
          <h2>À Propos du PNGP</h2>
          <p>
            Le Parti de la Nouvelle Génération Politique (PNGP) est un mouvement progressiste prônant la justice sociale, la transparence politique et l'innovation pour le développement durable.
            Nous croyons en une approche participative et équitable, plaçant l'intérêt général au cœur de nos actions.
          </p>
        </div>
      </section>
    <br></br>
      {/* Section Objectives */}
      <section className="objectives py-5 bg-light">
        <div className="container">
          <h2 className="text-center">Nos Principaux Objectifs</h2>
          <br></br>
          <br></br>
          <div className="row">
            <div className="col-md-4 text-center">
              <i className="fas fa-balance-scale fa-3x text-primary mb-3"></i>
              <h3>Démocratie & Transparence</h3>
              <p>Renforcer la participation citoyenne et la responsabilité politique.</p>
              
          <br></br>
          <br></br>
            </div>
            <div className="col-md-4 text-center">
              <i className="fas fa-leaf fa-3x text-success mb-3"></i>
              <h3>Développement Durable</h3>
              <p>Promouvoir les énergies renouvelables et lutter contre le changement climatique.</p>
              
          <br></br>
          <br></br>
            </div>
            <div className="col-md-4 text-center">
              <i className="fas fa-lightbulb fa-3x text-warning mb-3"></i>
              <h3>Innovation Économique</h3>
              <p>Favoriser l'innovation technologique et la création d'emplois de qualité.</p>
              
          <br></br>
          <br></br>
            </div>
          </div>
        </div>
      </section>
      <br></br>
      {/* Section Values */}
      <section className="values py-5">
        <div className="container">
          <h2 className="text-center">Nos Valeurs Fondamentales</h2>
          <br></br>
          <br></br>
          <div className="row">
            <div className="col-md-4 text-center">
              <i className="fas fa-hands-helping fa-3x text-info mb-3"></i>
              <h3>Justice Sociale</h3>
              <p>Œuvrer pour une redistribution équitable des richesses et l'égalité des chances.</p>
              
          <br></br>
          <br></br>
            </div>
            
            <div className="col-md-4 text-center">
              <i className="fas fa-users fa-3x text-primary mb-3"></i>
              <h3>Éducation</h3>
              <p>Investir dans une éducation de qualité et accessible à tous.</p>
              
          <br></br>
          <br></br>
            </div>
            <div className="col-md-4 text-center">
              <i className="fas fa-heart fa-3x text-danger mb-3"></i>
              <h3>Solidarité</h3>
              <p>Promouvoir la solidarité nationale et le bien-être de chaque citoyen.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
