import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section className="contact">
      <div className="container">
        <h1 className="text-center">Contactez-nous</h1>
        <p className="intro text-center">
          Nous serions ravis de répondre à vos questions et de recevoir vos suggestions. Remplissez le formulaire ci-dessous ou utilisez les informations de contact directes, et nous vous répondrons dans les plus brefs délais.
        </p>

        <div className="contact-details mt-5">
          <div className="contact-item">
            <div className="contact-info card">
              <div className="card-body text-center">
                <i className="fas fa-map-marker-alt fa-3x text-primary mb-3 animated-icon"></i>
                <h3 className="card-title">Siège Social</h3>
                <p className="card-text">
                  Ndjamena, Tchad<br />
                  Quartier Amtoukoui, avenue Taiwan<br />
                  BP 7300
                </p>
              </div>
            </div>
          </div>

          <div className="contact-item">
            <div className="contact-info card">
              <div className="card-body text-center">
                <i className="fas fa-phone fa-3x text-success mb-3 animated-icon"></i>
                <h3 className="card-title">Téléphone</h3>
                <p className="card-text">
                  +235 69518900
                </p>
              </div>
            </div>
          </div>

          <div className="contact-item">
            <div className="contact-info card">
              <div className="card-body text-center">
                <i className="fas fa-envelope fa-3x text-warning mb-3 animated-icon"></i>
                <h3 className="card-title">E-mail</h3>
                <p className="card-text">
                  accueil&#64;pngp-tchad.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
