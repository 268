import React from 'react';
import './Equipe.css';

const Equipe = () => {
  return (
    <section className="team">
      <div className="container">
        <h1 className="text-center">Notre Équipe</h1>
        <p className="intro text-center">
          Notre équipe est composée de leaders dévoués, engagés à apporter un changement positif et à bâtir un avenir meilleur pour le Tchad.
        </p>

        <div className="row">
          <div className="col-md-4 d-flex">
            <div className="team-member card text-center">
              <div className="card-body">
                <i className="fas fa-user-tie fa-3x text-primary mb-3 animated-icon"></i>
                <h3 className="card-title">Président du Parti</h3>
                <h4 className="card-title" style={{ color: '#ff8000' }}>Oumar Ousmane Brahim</h4>
                <p className="card-text">Leader politique, engagé dans la défense des droits et l'égalité sociale, garant des orientations stratégiques du parti.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex">
            <div className="team-member card text-center">
              <div className="card-body">
                <i className="fas fa-chart-line fa-3x text-success mb-3 animated-icon"></i>
                <h3 className="card-title">Responsable des Affaires Économiques</h3>
                <p className="card-text">Expert en développement économique, axé sur la création d'emplois et la promotion d'une économie inclusive et durable.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex">
            <div className="team-member card text-center">
              <div className="card-body">
                <i className="fas fa-laptop-code fa-3x text-warning mb-3 animated-icon"></i>
                <h3 className="card-title">Responsable des Innovations Technologiques</h3>
                <p className="card-text">Spécialiste en technologies émergentes, en charge de la modernisation et de la transformation numérique pour le progrès.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-4 d-flex">
            <div className="team-member card text-center">
              <div className="card-body">
                <i className="fas fa-handshake fa-3x text-danger mb-3 animated-icon"></i>
                <h3 className="card-title">Responsable de la Diplomatie</h3>
                <p className="card-text">Expert en relations internationales, engagé dans la promotion de la coopération et du dialogue pour la paix mondiale.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex">
            <div className="team-member card text-center">
              <div className="card-body">
                <i className="fas fa-leaf fa-3x text-info mb-3 animated-icon"></i>
                <h3 className="card-title">Responsable de l'Environnement</h3>
                <p className="card-text">Défenseur de la protection de l'environnement, promoteur des politiques écologiques et du développement durable.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex">
            <div className="team-member card text-center">
              <div className="card-body">
                <i className="fas fa-balance-scale fa-3x text-secondary mb-3 animated-icon"></i>
                <h3 className="card-title">Responsable de la Justice Sociale</h3>
                <p className="card-text">Passionné par l'équité, engagé dans la lutte contre les inégalités et pour l'accès équitable aux opportunités pour tous.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Equipe;
