import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleNavbar = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-blue">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/home">
                    <img
                        src="PNGP.jpg"
                        alt="Logo"
                        style={{ width: '70px', height: 'auto', borderRadius: '50%' }}
                    />
                    PNGP
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleNavbar}
                    aria-controls="navbarNav"
                    aria-expanded={!isCollapsed}
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to="/home">
                                <i className="fas fa-home"></i> Accueil
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to="/programme">
                                <i className="fas fa-book"></i> Programme
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to="/equipe">
                                <i className="fas fa-users"></i> Équipe
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to="/contact">
                                <i className="fas fa-envelope"></i> Contact
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => isActive ? 'active nav-link btn btn-primary text-white' : 'nav-link btn btn-primary text-white'}
                                to="/adhesion"
                            >
                                <i className="fas fa-user-plus"></i> Adhésion
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
