import React from 'react';
import './Programme.css';

const Programme = () => {
  return (
    <section className="programme">
      <div className="container">
        <h1 className="text-center animate-title">Programme du Parti de la Nouvelle Génération Politique</h1>
        <p className="intro text-center animate-fade">
          Notre programme repose sur des réformes visant à renforcer la démocratie, promouvoir la justice sociale et assurer un développement durable. Ensemble, construisons un avenir prospère pour le Tchad.
        </p>

        <div className="row mt-5">
          <div className="col text-center">
            <a className="btn btn-primary animate-bounce" href="/adhesion">
              <i className="fas fa-user-plus"></i> Rejoignez le Mouvement
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="card text-center animate-card">
              <div className="card-body">
                <i className="fas fa-balance-scale fa-3x text-primary mb-3 icon-pulse"></i>
                <h3 className="card-title">Renouveau Démocratique</h3>
                <p className="card-text">
                  Promouvoir la transparence et la participation citoyenne pour une démocratie plus inclusive, tout en impliquant davantage les jeunes dans les décisions politiques.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card text-center animate-card">
              <div className="card-body">
                <i className="fas fa-hand-holding-heart fa-3x text-success mb-3 icon-pulse"></i>
                <h3 className="card-title">Justice Sociale et Égalité</h3>
                <p className="card-text">
                  Lutter contre les inégalités en garantissant un accès équitable à l'éducation, aux soins de santé, et aux opportunités pour tous les citoyens.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card text-center animate-card">
              <div className="card-body">
                <i className="fas fa-leaf fa-3x text-warning mb-3 icon-pulse"></i>
                <h3 className="card-title">Développement Durable</h3>
                <p className="card-text">
                  Adopter des politiques respectueuses de l’environnement, promouvoir les énergies renouvelables et lutter activement contre le changement climatique.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-4">
            <div className="card text-center animate-card">
              <div className="card-body">
                <i className="fas fa-lightbulb fa-3x text-danger mb-3 icon-pulse"></i>
                <h3 className="card-title">Modernisation de l'Économie</h3>
                <p className="card-text">
                  Soutenir l'innovation, encourager l'entrepreneuriat, et créer des emplois de qualité, tout en assurant une croissance économique inclusive et durable.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card text-center animate-card">
              <div className="card-body">
                <i className="fas fa-globe fa-3x text-info mb-3 icon-pulse"></i>
                <h3 className="card-title">Diplomatie et Coopération Internationale</h3>
                <p className="card-text">
                  Promouvoir la paix et la coopération internationale tout en défendant les droits humains et en jouant un rôle actif sur la scène mondiale.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card text-center animate-card">
              <div className="card-body">
                <i className="fas fa-users fa-3x text-secondary mb-3 icon-pulse"></i>
                <h3 className="card-title">Inclusion Sociale</h3>
                <p className="card-text">
                  Lutter contre toutes formes de discrimination et favoriser l'égalité des chances en créant des opportunités pour tous les citoyens, en particulier les jeunes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Programme;
