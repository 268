// Importation de Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Programme from './components/Programme/Programme';
import Equipe from './components/Equipe/Equipe';
import Contact from './components/Contact/Contact';
import Adhesion from './components/Adhesion/Adhesion';

const App = () => {
  return (
    <Router>
      <Navbar /> {/* Navbar affichée sur chaque page */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/adhesion" element={<Adhesion />} />
        <Route path="/programme" element={<Programme />} />
        <Route path="/equipe" element={<Equipe />} /> {/* Route pour l'équipe */}
        <Route path="/contact" element={<Contact />} /> {/* Route pour le contact */}
      </Routes>
      <Footer /> {/* Footer affiché sur chaque page */}
    </Router>
  );
};

export default App;
