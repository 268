import React, { useState, useEffect } from 'react';
import AdhesionService from '../../services/AdhesionService';
import './Adhesion.css';

const Adhesion = () => {
  const [adherentCount, setAdherentCount] = useState(0);
  const [formData, setFormData] = useState({ nom: '', email: '', telephone: '' });
  const [secretCode, setSecretCode] = useState('');
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  useEffect(() => {
    const fetchAdherentCount = async () => {
      try {
        const count = await AdhesionService.countAdherents();
        setAdherentCount(count);
      } catch (error) {
        console.error("Erreur lors de la récupération du nombre d'adhérents :", error);
        alert("Erreur lors de la récupération du nombre d'adhérents.");
      }
    };

    fetchAdherentCount();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Conditions de validation
    if (formData.nom.length < 3) {
      alert("Le nom doit contenir au moins 3 caractères.");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert("Veuillez entrer un email valide.");
      return;
    }
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.telephone)) {
      alert("Le téléphone doit contenir 10 chiffres.");
      return;
    }

    try {
      const adherentExists = await AdhesionService.checkIfAdherentExists(formData.email);
      if (adherentExists) {
        alert('Un adhérent avec cet email existe déjà.');
        return;
      }

      await AdhesionService.addAdherent(formData);
      alert('Adhésion réussie !');
      setFormData({ nom: '', email: '', telephone: '' });
      setAdherentCount(adherentCount + 1);
    } catch (error) {
      console.error("Erreur lors de l'adhésion :", error);
      alert("Erreur lors de l'adhésion.");
    }
  };

  const checkSecretCode = () => {
    if (secretCode === '1O1O1B') {
      setShowDownloadButton(true);
    } else {
      alert('Code secret incorrect');
    }
  };

  const exportAdhesions = async () => {
    try {
      const adherents = await AdhesionService.getAdherentsOnce();
      const headers = ['Nom', 'Email', 'Téléphone'];
      const rows = adherents.map(({ nom, email, telephone }) => [nom, email, telephone]);

      const csvContent = [headers, ...rows]
        .map((row) => row.join(','))
        .join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      
      if (isMobile) {
        window.open(url, '_blank');
      } else {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'adherents.csv';
        link.click();
      }

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erreur lors de l'exportation des adhésions :", error);
      alert("Erreur lors de l'exportation des adhésions.");
    }
  };

  return (
    <section className="adhesion">
      <div className="container">
        <h1 className="text-center">Rejoignez notre Mouvement</h1>
        <p className="intro text-center">
          Remplissez le formulaire ci-dessous pour adhérer au Parti de la Nouvelle Génération Politique.
        </p>

        <div className="row mt-5 justify-content-center">
          <div className="col-md-6 text-center d-flex align-items-center justify-content-center">
            <div>
              <i className="fas fa-users adherent-icon"></i>
              <h3>Nombre d'adhérents : {adherentCount}</h3>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5 mb-5">
          <div className="col-md-6">
            <form onSubmit={handleSubmit} className="adhesion-form card">
              <div className="card-body">
                <h3 className="card-title">Formulaire d'Adhésion</h3>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                    placeholder="Votre Nom"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Votre Email"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="telephone"
                    value={formData.telephone}
                    onChange={handleChange}
                    placeholder="Votre Téléphone"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary btn-block mt-4">
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-md-6 text-center">
            <h3 className="card-title">Télécharger la liste des adhérents actuels</h3>
            <div className="form-group mt-4">
              <i className="fas fa-key secret-icon"></i>
              <input
                type="text"
                className="form-control"
                value={secretCode}
                onChange={(e) => setSecretCode(e.target.value)}
                placeholder="Entrez le code secret"
              />
              <button onClick={checkSecretCode} className="btn btn-secondary mt-3">
                Vérifier le Code
              </button>
            </div>
            {showDownloadButton && (
              <button onClick={exportAdhesions} className="btn btn-success mt-3">
                Télécharger la liste des adhérents
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Adhesion;
