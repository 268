// src/services/AdhesionService.js
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Correction du chemin pour la configuration Firestore

class AdhesionService {
  constructor() {
    this.collectionRef = collection(db, 'adherents'); // Référence correcte à Firestore
  }

  async addAdherent(adherent) {
    try {
      await addDoc(this.collectionRef, adherent);
      console.log('Adhérent ajouté avec succès à Firestore :', adherent);
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'adhérent à Firestore :", error);
      throw error; // Pour permettre la gestion des erreurs côté appelant si nécessaire
    }
  }

  async getAdherentsOnce() {
    try {
      const querySnapshot = await getDocs(this.collectionRef);
      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Erreur lors de la récupération des adhérents :', error);
      return [];
    }
  }

  async countAdherents() {
    try {
      const querySnapshot = await getDocs(this.collectionRef);
      return querySnapshot.size;
    } catch (error) {
      console.error('Erreur lors du comptage des adhérents :', error);
      return 0;
    }
  }

  async checkIfAdherentExists(email) {
    const q = query(this.collectionRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // Renvoie `true` si l'adhérent existe déjà
  }
}


export default new AdhesionService();
